// NOTE: effect_price just 特例，迁移旧官网遗留路由
import { SEO } from '@renderbus/common/components'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../molecules/layout'
import {
  BgImg,
  Card,
  CardImg,
  CardTitle,
  CardTitleImg,
  CardWrapper,
  Content,
  ContentContainer,
  FlowerImg,
  GatsbyImg,
  GetIcon,
  GetItem,
  Gift,
  InnerImg,
  RabbitImg,
  RabbitImgMb,
  RechargeButton,
  RechargeIcon,
  RechargeItem,
  RulesItem,
  RulesWrapper,
} from './architectural-rendering-monthly-promotion.atom'

import StarImage from '../images/architectural-rendering-monthly-promotion/star.png'
import { MidAutumnBanner2024 } from '../molecules/banner/mid-autumn-2024'
import { UserAuthorizationService, baseLink } from '@renderbus/common/service'

const rechargeList = [
  {
    recharge: 200,
    get: 240,
    gift: 20,
    title: '限时',
  },
  {
    recharge: 500,
    get: 650,
    gift: 30,
    title: '限时',
  },
  {
    recharge: 1000,
    get: 1400,
    gift: 40,
    title: '火爆',
  },
  {
    recharge: 3000,
    get: 4500,
    gift: 50,
    title: '超值',
  },
  {
    recharge: 5000,
    get: 8000,
    gift: 60,
    title: '最划算',
  },
]

const rechargeRules = [
  '本次活动仅限于Renderbus效果图用户参与，活动有效期为2024.9.13-2024.10.23。',
  '活动期间充值200元送40元、充值500元送150元，充值1000元送400元，充值3000元送1500元，充值5000送3000，多充多得，如需充值5000以上金额请联系客服享受更高优惠。',
  '赠送金额为效果图无门槛渲染券，渲染券有效期为3个月（有效期以充值当天起算），充值完成即发放，有疑问可联系客服。',
  '每位用户不限制参与活动次数。',
  '本活动最终解析权利归深圳市瑞云科技股份有限公司所有。',
]

class ArchitecturalRenderingMonthlyPromotion extends React.PureComponent {
  handleClick(e) {
    e.nativeEvent.stopImmediatePropagation()
    if (typeof window !== 'undefined') {
      window.location.href = !!UserAuthorizationService.isLogined()
        ? `${baseLink(true)}/center/user/topUp`
        : `${baseLink()}/sso/register?businessType=0`
    }
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            bg: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/bg.png/" }
            ) {
              ...fluidImage
            }
            innerBg: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/inner-bg.webp/" }
            ) {
              ...fluidImage
            }
            rabbit: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/rabbit.webp/" }
            ) {
              ...fluidImage
            }
            rabbitMb: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/rabbit-mb.png/" }
            ) {
              ...fluidImage
            }
            flower: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/flower.png/" }
            ) {
              ...fluidImage
            }
            bgMb: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/bg-mobile.png/" }
            ) {
              ...fluidImage
            }
            cardTitle: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/card-title.webp/" }
            ) {
              ...fluidImage
            }
            card: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/card.webp/" }
            ) {
              ...fluidImage
            }
            getIcon: file(
              relativePath: { regex: "/architectural-rendering-monthly-promotion/get-icon.png/" }
            ) {
              ...fluidImage
            }
            rechargeIcon: file(
              relativePath: {
                regex: "/architectural-rendering-monthly-promotion/recharge-icon.png/"
              }
            ) {
              ...fluidImage
            }
            rechargeBtn: file(
              relativePath: {
                regex: "/architectural-rendering-monthly-promotion/recharge-btn-hover.webp/"
              }
            ) {
              ...fluidImage
            }
            rechargeBtnHover: file(
              relativePath: {
                regex: "/architectural-rendering-monthly-promotion/recharge-btn.webp/"
              }
            ) {
              ...fluidImage
            }
          }
        `}
        render={({
          bg,
          bgMb,
          innerBg,
          rabbit,
          rabbitMb,
          cardTitle,
          card,
          getIcon,
          rechargeIcon,
          rechargeBtn,
          rechargeBtnHover,
          flower,
        }) => (
          <Layout location={location} topThreeShare={topThreeShare}>
            <SEO
              title='效果图渲染欢度中秋国庆，充值立享超值优惠-瑞云渲染'
              keywords='效果图渲染中秋充值活动,效果图渲染国庆充值活动,效果图渲染充值优惠'
              description='瑞云渲染为庆祝中秋国庆双节，特别推出效果图渲染充值优惠活动！现在充值，即可享受额外赠送的渲染券，充值金额越多，赠送的渲染券比例越高，最高可达60%。目标是确保每位用户都能享受到超值的优惠，同时体验到瑞云渲染的高效和专业服务。'
            />
            <ContentContainer theme='black' id='price-middle'>
              <MidAutumnBanner2024 inPage={true} />
              <Content>
                <BgImg
                  fluid={[
                    bg.childImageSharp.fluid,
                    { ...bgMb.childImageSharp.fluid, media: `(max-width: 780px)` },
                  ]}
                />
                <InnerImg fluid={[innerBg.childImageSharp.fluid]} />
                <RabbitImg fluid={[rabbit.childImageSharp.fluid]} />
                <FlowerImg fluid={[flower.childImageSharp.fluid]} />
                <CardWrapper>
                  {rechargeList.map((item, index) => {
                    return (
                      <Card key={index} index={index}>
                        <CardImg fluid={[card.childImageSharp.fluid]} />
                        <CardTitle>
                          <CardTitleImg fluid={[cardTitle.childImageSharp.fluid]} />
                          <span>{item.title}</span>
                        </CardTitle>
                        <RechargeItem>
                          <RechargeIcon>
                            <GatsbyImg fluid={[rechargeIcon.childImageSharp.fluid]} />
                          </RechargeIcon>
                          <div className='price'>
                            <div className='value'>{item.recharge}</div>
                            <div className='unit'>元</div>
                          </div>
                        </RechargeItem>
                        <GetItem>
                          <GetIcon>
                            <GatsbyImg fluid={[getIcon.childImageSharp.fluid]} />
                          </GetIcon>
                          <div className='price'>
                            <div className='value'>{item.get}</div>
                            <div className='unit'>元</div>
                          </div>
                        </GetItem>
                        <Gift>
                          - 赠送<span>{item.gift}</span>% -
                        </Gift>
                      </Card>
                    )
                  })}
                  <RabbitImgMb fluid={[rabbitMb.childImageSharp.fluid]} />
                </CardWrapper>
                <RechargeButton onClick={this.handleClick}>
                  <GatsbyImg className='normal' fluid={[rechargeBtn.childImageSharp.fluid]} />
                  <GatsbyImg className='hover' fluid={[rechargeBtnHover.childImageSharp.fluid]} />
                  <span>立即充值</span>
                </RechargeButton>
                <RulesWrapper>
                  <div className='title'>活动规则</div>
                  {rechargeRules.map((item, index) => (
                    <RulesItem key={index}>
                      <img src={StarImage} alt='' />
                      <span>{item}</span>
                    </RulesItem>
                  ))}
                </RulesWrapper>
              </Content>
            </ContentContainer>
          </Layout>
        )}
      />
    )
  }
}

export default ArchitecturalRenderingMonthlyPromotion
